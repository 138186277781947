define("discourse/plugins/discourse-docs/discourse/templates/components/docs-tag", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a
    href
    {{action this.selectTag}}
    class="docs-item docs-tag
      {{if this.tag.active 'selected'}}{{if this.subtag 'subtag'}}"
  >
    {{#unless this.tag.active}}
      {{d-icon "plus"}}
    {{/unless}}
  
    {{#if this.tag.active}}
      {{d-icon "circle-xmark"}}
    {{/if}}
  
    <span class="docs-item-id tag-id">{{this.tag.id}}</span>
    <span class="docs-item-count tag-count">{{this.tag.count}}</span>
  </a>
  */
  {
    "id": "Wx6vbVYT",
    "block": "[[[11,3],[24,6,\"\"],[16,0,[29,[\"docs-item docs-tag\\n    \",[52,[30,0,[\"tag\",\"active\"]],\"selected\"],[52,[30,0,[\"subtag\"]],\"subtag\"]]]],[4,[38,1],[[30,0],[30,0,[\"selectTag\"]]],null],[12],[1,\"\\n\"],[41,[51,[30,0,[\"tag\",\"active\"]]],[[[1,\"    \"],[1,[28,[35,3],[\"plus\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"tag\",\"active\"]],[[[1,\"    \"],[1,[28,[35,3],[\"circle-xmark\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,1],[14,0,\"docs-item-id tag-id\"],[12],[1,[30,0,[\"tag\",\"id\"]]],[13],[1,\"\\n  \"],[10,1],[14,0,\"docs-item-count tag-count\"],[12],[1,[30,0,[\"tag\",\"count\"]]],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"action\",\"unless\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-docs/discourse/templates/components/docs-tag.hbs",
    "isStrictMode": false
  });
});